import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_metainfo, null, {
        title: _withCtx(({ content }) => [
          _createTextVNode(_toDisplayString(content ? `${content} | ${_ctx.siteName}` : _ctx.siteName), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view),
      _createVNode(_component_notifications)
    ]),
    _: 1
  }))
}