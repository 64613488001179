import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetCountResponse,
    GetPaymentCountRequest,
    GetPaymentListRequest, GetPaymentPurposeRequest,
    Payment, PaymentPurpose, PaymentStatus
} from "@/store/modules/payments/models";


export default <Module<any, any>>{
    namespaced: true,
    state: {
        statuses: [] as PaymentStatus[],
        purposes: [] as PaymentPurpose[],
    },
    getters: {
        statuses(state): PaymentStatus[] {
            return state.statuses;
        },
        purposes(state): PaymentPurpose[] {
            return state.purposes;
        }
    },
    mutations: {
        setStatuses(state, statuses: PaymentStatus[]) {
            state.statuses = statuses;
        },
        setPurposes(state, purposes: PaymentPurpose[]) {
            state.purposes = purposes;
        }
    },
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetPaymentListRequest): Promise<Payment[]> {
            const response  = await api.get<Payment[]>('api/lk/v1/payments', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetPaymentListRequest): Promise<Payment[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getCount(ctx, request: GetPaymentCountRequest): Promise<number> {
            const response  = await api.get<GetCountResponse>('api/lk/v1/payments/count', { params: request });
            return response.data.count;
        },
        async fetchStatuses(ctx): Promise<PaymentStatus[]> {
            const response  = await api.get<PaymentStatus[]>('api/lk/v1/payment_statuses');
            ctx.commit('setStatuses', response.data);
            return response.data;
        },
        async fetchPurposes(ctx, request: GetPaymentPurposeRequest): Promise<PaymentPurpose[]> {
            const response  = await api.get<PaymentPurpose[]>('api/lk/v1/payment_purposes', { params: request });
            ctx.commit('setPurposes', response.data);
            return response.data;
        },
    }
};