import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetCountRequest,
    GetCountResponse,
    GetOilCardListRequest, GetStatisticsCountRequest, GetStatisticsCountResponse, GetStatisticsRequest,
    OilCard, OilCardStatistic,
    UpdateOilCardRequest
} from "@/store/modules/oilCard/models";


export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetOilCardListRequest): Promise<OilCard[]> {
            const response  = await api.get<OilCard[]>('api/lk/v1/oil_cards', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetOilCardListRequest): Promise<OilCard[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async update(ctx, request: UpdateOilCardRequest): Promise<OilCard> {
            const response  = await api.post<OilCard>(`api/lk/v1/oil_cards/${request.id}`, request);
            return response.data;
        },
        async getCount(ctx, request: GetCountRequest): Promise<number> {
            const response  = await api.get<GetCountResponse>('api/lk/v1/oil_cards/count', { params: request });
            return response.data.count;
        },
        async getStatistics(ctx, request: GetStatisticsRequest): Promise<OilCardStatistic[]> {
            const response  = await api.get<OilCardStatistic[]>('api/lk/v1/oil_cards/statistics', { params: request });
            return response.data;
        },
        async getStatisticsCount(ctx, request: GetStatisticsCountRequest): Promise<number> {
            const response  = await api.get<GetStatisticsCountResponse>('api/lk/v1/oil_cards/statistics/count', { params: request });
            return response.data.count;
        },
    }
};