<template>
  <v-app>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | ${siteName}` : siteName }}</template>
    </metainfo>
    <router-view/>
    <notifications />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {Notifications} from "@kyvg/vue3-notification";
import {mapGetters} from "vuex";
import { useMeta } from 'vue-meta';

export default defineComponent({
  setup () {
    useMeta({
      title: '',
    })
  },
  name: 'App',
  components: {Notifications},
  computed: {
    ...mapGetters('auth', [
        'userData',
    ]),
    siteName(): string {
      return process.env.VUE_APP_SITE_NAME ? process.env.VUE_APP_SITE_NAME : '';
    }
  },
  data () {
    return {
      //
    }
  },
  mounted() {
    if (this.userData === null && location.pathname !== '/login') {
      this.$router.push({ name: 'login' });
    }
  },
  watch: {
    userData() {
      if (this.userData === null) {
        this.$router.push({ name: 'login' });
      }
    }
  }
})
</script>
