import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {GetNewsCountRequest, GetNewsCountResponse, GetNewsListRequest, News} from "@/store/modules/news/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetNewsListRequest): Promise<News[]> {
            const response  = await api.get<News[]>('api/lk/v1/news', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetNewsListRequest): Promise<News[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getCount(ctx, request: GetNewsCountRequest): Promise<number> {
            const response  = await api.get<GetNewsCountResponse>('api/lk/v1/news/count', { params: request });
            return response.data.count;
        },
    }
};