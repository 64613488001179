import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetOilCardStatisticsRequest,
    GetOilGroupTypeStatisticsRequest,
    GetOilTypeSourceStatisticsRequest,
    GetTransactionListRequest,
    OilCardTransactionStatistics,
    OilGroupTypeTransactionStatistics, OilTypeSourceTransactionStatistics,
    Transaction
} from "@/store/modules/transactions/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async getOilGroupTypeStatistics(ctx, request: GetOilGroupTypeStatisticsRequest): Promise<OilGroupTypeTransactionStatistics> {
            const response  = await api.get<OilGroupTypeTransactionStatistics>('api/lk/v1/transactions/statistics/oil_group_type', { params: request });
            return response.data;
        },
        async getOilCardsStatistics(ctx, request: GetOilCardStatisticsRequest): Promise<OilCardTransactionStatistics[]> {
            const response  = await api.get<OilCardTransactionStatistics[]>('api/lk/v1/transactions/statistics/oil_card', { params: request });
            return response.data;
        },
        async getOilTypeSourceStatistics(ctx, request: GetOilTypeSourceStatisticsRequest): Promise<OilTypeSourceTransactionStatistics[]> {
            const response  = await api.get<OilTypeSourceTransactionStatistics[]>('api/lk/v1/transactions/statistics/oil_type_source', { params: request });
            return response.data;
        },
        async fetchList(ctx, request: GetTransactionListRequest): Promise<Transaction[]> {
            const response  = await api.get<Transaction[]>('api/lk/v1/transactions', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetTransactionListRequest): Promise<Transaction[]> {
            return await ctx.dispatch('fetchList', request);
        },
    }
};