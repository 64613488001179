import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    CompanyContract,
    GetCompanyContractListRequest,
    UpdateCompanyContractRequest
} from "@/store/modules/companyContract/models";


export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetCompanyContractListRequest): Promise<CompanyContract[]> {
            const response  = await api.get<CompanyContract[]>('api/lk/v1/company_contracts', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetCompanyContractListRequest): Promise<CompanyContract[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async update(ctx, request: UpdateCompanyContractRequest): Promise<CompanyContract> {
            const response  = await api.post<CompanyContract>(`api/lk/v1/company_contracts/${request.id}`, request);
            return response.data;
        }
    }
};