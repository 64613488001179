import axios, {AxiosInstance, InternalAxiosRequestConfig} from 'axios'
import store from "@/store";
import {UserData} from "@/store/modules/auth/models";

const api: AxiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_URL}`,
    timeout: 3600000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8'
    }
})

const requestInterceptor = (config: InternalAxiosRequestConfig<any>):  InternalAxiosRequestConfig<any> => {
    const dataStr = localStorage.getItem('aoil:lk:user');
    if (null === dataStr) {
        return config;
    }

    const user = JSON.parse(dataStr) as UserData;

    if (user && user.token) {
        config.headers['Authorization'] = `Bearer ${user.token}`
    }

    if (config.method?.toLowerCase() === 'patch' || config.method?.toLowerCase() === 'delete') {
        config.headers['Content-Type'] = 'application/merge-patch+json'
    }

    return config
}

api.interceptors.request.use(requestInterceptor)

api.interceptors.response.use(function (response) {
    return Promise.resolve(response)
}, function (error) {
    let status, message

    if (error.response) {
        status = error.response.status
        message = error.response.data.message ?? error.response.data.detail

        if (status === 401) {
            message = 'Неверный логин или пароль'
            store.dispatch('auth/logout');
        } else if (status === 403 && message === 'Ведутся работы') {
            window.location.pathname = 'sorry';
        }
    } else {
        status = 500
        message = 'Ошибка сервера'
    }

    return Promise.reject(`[${status}] ${message}`)
})

export default api