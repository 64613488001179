import {ActionTree, Module, MutationTree} from "vuex";
import api from "@/plugins/api";
import {Account} from "@/store/modules/account/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        account: null as Account|null,
    },
    getters: {
        account(state): Account|null {
            return state.account;
        }
    },
    mutations: <MutationTree<any>>{
        setAccount(state, data: Account) {
            state.account = data;
        },
        reset(state) {
            state.account = null;
        }
    },
    actions: <ActionTree<any, any>>{
        reset({commit}) {
            commit('reset');
        },
        async get(ctx): Promise<Account> {
            const response  = await api.get<Account>('api/lk/v1/account');
            ctx.commit('setAccount', response.data);
            return response.data;
        },
    }
};