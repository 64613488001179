import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    Bill,
    CreateBillRequest,
    GetBillCountRequest,
    GetBillCountResponse,
    GetBillListRequest, GetBillPdfPathResponse, SendBillByEmailRequest
} from "@/store/modules/bills/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetBillListRequest): Promise<Bill[]> {
            const response  = await api.get<Bill[]>('api/lk/v1/bills', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetBillListRequest): Promise<Bill[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getCount(ctx, request: GetBillCountRequest): Promise<number> {
            const response  = await api.get<GetBillCountResponse>('api/lk/v1/bills/count', { params: request });
            return response.data.count;
        },
        async createBill(ctx, request: CreateBillRequest): Promise<Bill> {
            const response  = await api.post<Bill>('api/lk/v1/bills', request);
            return response.data;
        },
        async sendBillByEmail(ctx, request: SendBillByEmailRequest) {
            await api.post(`api/lk/v1/bills/${request.billId}/send_email`, request);
        },
        async getBillPdfPath(ctx, id: string): Promise<string> {
            const response = await api.get<GetBillPdfPathResponse>(`api/lk/v1/bills/${id}/pdf/path`);
            return response.data.path;
        }
    }
};